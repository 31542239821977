@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.min.js');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');
body{
    font-family: 'Oswald', sans-serif;
}
body p{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 25.6px;
    line-height: 28px;
    color: #000000;
}
body a{
    text-decoration: none;
}
body .link_btn{
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    fill: #ffffff;
    color: #ffffff;
    background-color: #cb1313;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #cb1313;
    padding: 15px 30px;
    border-radius: 40px 40px 40px 40px;
    font-family: 'Oswald', sans-serif;
    display: table;
}
body h6{
    color: #969fa8;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 45px;
}
body h2{
    color: #111111;
    font-size: 32px;
    line-height: 48px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 700;
}
.navbar_wrapper
{
    background-color: rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
}

.navbar_wrapper .navbar 
{
    background-color: transparent !important;
    padding: 5px 0;
}
.navbar_wrapper .navbar .navbar-collapse  
{
    justify-content: flex-end;
}
.navbar_wrapper .navbar li
{
    margin-left: 30px;
}
.navbar_wrapper .navbar a{
    color: #fff;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
.navbar_wrapper .navbar-brand{
    padding: 0;
}
.navbar_wrapper .navbar-brand img{
    width: 89px;
}

/* .banner_wrapper{
    background: url('../image/banner.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 150px 0 70px;
} */
.banner_wrapper{
    width: 100%;
    height: 611px;
    overflow: hidden;
    position: relative;
}
.banner_wrapper .container{
    position: absolute !important;
    top: 55%;
    transform: translateY(-50%);
    left: 105px;
    z-index: 9 !important;
}

.video{
    width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner_wrapper:after, .cta_wrapper:after{
    position: absolute;
    content: "";
    background-color: rgb(0 0 0 / 35%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
.banner_wrapper .container, .cta_wrapper .container{
    position: relative;
    z-index: 1;
}
.banner_wrapper h1{
    font-size: 70px;
    line-height: 72px;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 700;
    text-transform: uppercase;
}
.banner_wrapper p{
    margin: 15px 0;
}
.about_wrapper{
    padding: 80px 0;
}
.about_wrapper img{
    width: 70%;
    display: table;
    margin-left: auto;
    margin-right: 30px;
}
.aboutImg{
    position: relative;
}
.aboutImg video{
    width: 78%;
    height: 630px;
}
.aboutImg:after
{
    content: "";
    background: #d90000b5;
    height: auto;
    width: 63%;
    position: absolute;
    top: -26px;
    left: 104px;
    bottom: -26px;
    z-index: -1;
}
.services_wrapper
{
    background-color: #f7f7f7;
    padding: 80px 0;
}
.services_wrapper .services_cont
{
    position: relative;
    padding-left: 98px;
    padding-top: 30px;
}
.services_wrapper .services_cont img
{
    width: 90px;
    position: absolute;
    top: 30px;
    left: 0;
}
.services_wrapper .services_cont p
{
    color: #2d2a27;
    font-size: 14px;
    line-height: 22px;
}
.services_wrapper .services_cont h5
{
    margin-bottom: 10px;
    color: #404040;
    text-align: inherit;
    font-size: 24px;
    line-height: 30px;    
    font-weight: 700;
}
.cta_wrapper{
    background: url(../image/ctaBG.webp);
    background-position: top center;
    background-size: cover;
    padding: 100px 0;
    position: relative;
    background-attachment: fixed;
}
.trainer_wrapper{
    padding: 60px 0;
    background: #f7f7f7;
}
.trainer_wrapper .trainer_cont{
    position: relative;
    max-height: 480px;
    overflow: hidden;
}
.trainer_wrapper .trainer_cont .overlay_cont{
    background-color: rgba(0,0,0,0.75);
    top: 0;
    width: 100%;
    max-width: 100%;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0;
    transition: 0.5s ease;
}
.trainer_wrapper .trainer_cont:hover .overlay_cont{
    opacity: 1;
}
.trainer_wrapper .trainer_cont .overlay_cont div{
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
}
.trainer_wrapper .trainer_cont .overlay_cont h4{
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
}
footer{
    padding: 12px 0;  
    background-color: #111111; 
}
footer a img{
    width: 86px;
}
footer .col-12{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}
.contact_wrapper{
    padding: 70px 0;
}
.contact_wrapper .contact_cont{
    padding-left: 25px;
    padding-top: 30px;
}
.contact_wrapper iframe{
    width: 100%;
    height: 430px;
}
.contact_wrapper ul
{
    margin: 20px 0;
    padding: 0;
    list-style: none;
}
.contact_wrapper ul li
{
   position: relative;
   padding-left: 41px;
   margin-bottom: 12px;
}
.contact_wrapper li i
{    
    font-size: 26px;
    top: 4px;
    left: 0;
    position: absolute;
    fill: #cb1313;
    color: #cb1313;
}
.contact_wrapper h3
{
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 30px;
    font-weight: 700;
    color: #000000;
}
.contact_wrapper p
{
    margin: 0;
}
.social a
{
    height: 30px;
    width: 30px;
    line-height: 28px;
    display: inline-table;
    background: #cb1313;
    color: #fff;
    text-align: center;
    font-size: 17px;
    margin-right: 10px;
}
.liveChange_wrapper, .testimonial_wrapper, .instagram_wrapper{
    padding: 80px 0; 
}
.instagram_wrapper .posts{
    display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.instagram_wrapper .posts .post{
  width: 32%;
}
.instagram_wrapper .posts .post img, .instagram_wrapper .posts .post video
{
    width: 100%;
    height: 420px;
    object-fit: cover;
}
.liveChange_wrapper .imgCont {
    background: #d90000b5;
    padding: 7px;
    width: 91%;
    margin-top: 20px;
  }
.testimonial_wrapper ul{
    padding: 0;
    list-style: none;
    margin: 30px 0 0;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}
.testimonial_wrapper ul li{
    width: 30%;
    text-align: left;
    border: 1px solid #e8e8e8;
    padding: 11px 11px 28px;
    background: #fff;
    position: relative;
}
.testimonial_wrapper ul li p {
    font-size: 15px;
    font-style: italic;
    line-height: 23px;
  }
.testimonial_wrapper ul li h4{
    margin: 0;
    font-size: 19px;
    position: absolute;
    bottom: 16px;
}
.whatspp_btn
{
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
}
.whatspp_btn img
{
    width: 70px;
}

@media (min-width: 1500px) {
    .aboutImg:after {
        left: 125px;
    }
}
@media (min-width: 1200px) and (max-width: 1300px){
    .aboutImg:after {
        left: 99px;
    }
    .banner_wrapper h1 {
        font-size: 50px;
        line-height: 56px;
    }
}
@media (min-width: 1023px) and (max-width: 1199px){
    .aboutImg:after {
        left: 77px;
    }
    .banner_wrapper h1 {
        font-size: 50px;
        line-height: 56px;
    }
}
@media (min-width: 768px) and (max-width: 1023px){
    .banner_wrapper h1 {
        font-size: 50px;
        line-height: 62px;
        margin-bottom: 15px;
     }
     .banner_wrapper p {
        font-weight: 400;
    }
    .aboutImg:after {
        width: 70%;
        left: 39px;
    }
    .banner_wrapper .col-md-6 {
        width: 100%;
    }
    .banner_wrapper .container {
        top: 45%;
        transform: translateY(-50%);
        left: 65px;
    }
    .banner_wrapper p {
        font-weight: 400;
        display: none;
    }
    .video {
        height: 681px;
       }
       .instagram_wrapper .posts .post img, .instagram_wrapper .posts .post video {
        height: 240px;
       }
       .liveChange_wrapper, .testimonial_wrapper, .instagram_wrapper {
        padding: 30px 0;
      }
}
@media (max-width: 767px){
    .aboutImg video {
        width: 86%;
        height: 640px;
      }
    .whatspp_btn {
        bottom: 10px;
        right: 10px;
    }
    .whatspp_btn img {
        width: 52px;
      }
    .liveChange_wrapper, .testimonial_wrapper, .instagram_wrapper {
        padding: 50px 0;
      }
    .instagram_wrapper .posts {
        gap: 7px;
      }
      .instagram_wrapper .posts .post {
        width: 48%;
      }
      .instagram_wrapper .posts .post img, .instagram_wrapper .posts .post video {
        width: 100%;
        height: 180px;
        object-fit: cover;
      }
    .testimonial_wrapper ul li h4 {
    font-size: 15px;
    }
    .testimonial_wrapper ul {
    gap: 12px;
    }
    .testimonial_wrapper ul li {
    width: 47%;
    padding: 6px 6px 28px;
    }
    .testimonial_wrapper ul li p {
        font-size: 13px;
        line-height: 21px;
      }
    .video {
        height: 680px;
       }
    .banner_wrapper h1 {
        font-size: 35px;
        line-height: 44px;
        margin-bottom: 32px;
    }
    .aboutImg {
        margin-bottom: 50px;
    }
    .about_wrapper img {
        width: 86%;
        margin-right: 0;
    }
    .aboutImg:after {
        width: 87%;
        top: -26px;
        left: 24px;
        bottom: -26px;
    }
    .banner_wrapper p {
        font-weight: 400;
        display: none;
    }
    .banner_wrapper .container {
        left: 0;
    }    
    .trainer_wrapper .trainer_cont {
        margin: 20px 0;
    }
    .navbar_wrapper .collapse.navbar-collapse
    {
        background: #000;
        padding: 10px 0;
    }
    footer .col-md-4, footer .col-md-4 p{
        text-align: center !important;
    }
    footer .social{
        margin: 15px 0 10px;
    }

}
